import React, { useState, createContext, useEffect } from 'react';

export const LocalContext = createContext();

export const DataLocalContext = (props) => {
	const existingTokens = JSON.parse(localStorage.getItem('tokens'));

	const [step, setStep] = useState(0);
	const [validPlate, setValidPlate] = useState('');
	const [plate, setPlate] = useState('');
	const [lat, setLat] = useState('');
	const [long, setLong] = useState('');
	const [address, setAddress] = useState('');
	const [authTokens, setAuthTokens] = useState(existingTokens || '');
	const [more, setMore] = useState(null);
	const [userName, setUserName] = useState();

	useEffect(() => {
		if (authTokens) {
			localStorage.setItem('tokens', JSON.stringify(authTokens));
		}
	}, [authTokens]);

	return <LocalContext.Provider value={[step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, authTokens, setAuthTokens, more, setMore, userName, setUserName]}>{props.children}</LocalContext.Provider>;
};
