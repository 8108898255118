import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import insertPic from "../../assets/insert2.jpg";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const InsertCard = () => {
	const smallMobile = useMediaQuery({ query: "(max-width: 500px)" });
	/* const mediumMobile = useMediaQuery({ query: '(min-width: 501px) and (max-width: 600px)' });
    const infra = useMediaQuery({ query: '(min-width: 601px) and (max-width: 800px)' });
    const supra = useMediaQuery({ query: '(min-width: 801px) and (max-width: 1200px)' });
    const mediumDesktop = useMediaQuery({ query: '(min-width: 1200px) and (max-width: 1800px)' });*/
	const bigDesktop = useMediaQuery({ query: "(min-width: 501px)" });

	return (
		<section className="insert-card">
			<div className={`main-card ${smallMobile ? "small-card" : ""}${bigDesktop ? "big-card" : ""}`}>
				<h2 style={{color:"orange",fontSize:"12px", textAlign:"center"}}>Un véhicule vous semble abandonné ? Renseignez-le afin d'avertir son propriétaire</h2>
				<img src={insertPic} className="insert-pic" alt="ajouter" />
				<Link to="/ajouter-plaque" style={{ textDecoration: "none" }}>
					<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" className="insert-btn" startIcon={<AddIcon />}>
						Ajouter plaque
					</Button>
				</Link>
			</div>
		</section>
	);
};

export default InsertCard;
