import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PicContext } from '../Context/PicContext';
import { LocalContext } from '../Context/LocalContext';

import errorPic from '../../../assets/error.png';
import VisualPlate from '../StepPlate/VisualPlate';
import Button from '@material-ui/core/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReplayIcon from '@material-ui/icons/Replay';
import ReplyIcon from '@material-ui/icons/Reply';
import CreateIcon from '@material-ui/icons/Create';

import addressPic from '../../../assets/address.png';
import Icon from '@material-ui/core/Icon';

function StepGeoloc() {
	// eslint-disable-next-line
	const [dataUri1, setDataUri1, dataUri2, setDataUri2, dataUri3, setDataUri3, images, setImages, picType, setPicType, choice, setChoice] = useContext(PicContext);
	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, more, setMore] = useContext(LocalContext); // eslint-disable-line
	const [style, setStyle] = useState({});
	const [text, setText] = useState('');
	const [error, setError] = useState(false);
	const [errorAddress, setErrorAddress] = useState(false);
	const [addressForm, setAddressForm] = useState(null);
	const [streetNumber, setStreetNumber] = useState(null);
	const [streetName, setStreetName] = useState(null);
	const [city, setCity] = useState(null);

	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data) => {
		fetch(`https://api.opencagedata.com/geocode/v1/json?q=${data.streetNumber} ${data.streetName} ${data.city}&key=9b58345250c04bd28d80090c442dacca`)
			.then((res) => res.text())
			.then((geo) => {
				let obj = JSON.parse(geo);
				setLat(obj.results[0].geometry.lng);
				setLong(obj.results[0].geometry.lat);
				setAddress(data.streetNumber + ' ' + data.streetName + ', ' + data.city);
				setStep('done');
			})
			.catch((error) => {
				setErrorAddress(true);
				console.log(error);
			});
	};

	const geoloc = () => {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				setLong(position.coords.latitude);
				setLat(position.coords.longitude);

				fetch(`https://api.opencagedata.com/geocode/v1/json?key=9b58345250c04bd28d80090c442dacca&q=${position.coords.latitude}+${position.coords.longitude}`)
					.then((res) => res.text())
					.then((geo) => {
						let obj = JSON.parse(geo);
						setAddress(obj.results[0].formatted);
						setStep('done');
					})
					.catch((error) => {
						setError(true);
						console.log(error);
					});
			},
			function error(err) {
				console.warn(`ERREUR (${err.code}): ${err.message}`);
			},
			{
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			}
		);
	};

	useEffect(() => {
		setTimeout(() => {
			setStyle({ opacity: 1, width: '100%' });
		}, 200);
		setTimeout(() => {
			setText('Étape 3/3');
		}, 1200);
		setMore(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const backTop = () => {
		setStep('pics');
		setPicType('');
		setChoice(false);
		setDataUri1('');
		setDataUri2('');
		setDataUri3('');
		setImages([]);

		if (addressForm) {
			setStep('geoloc');
			setStreetNumber(null);
			setStreetName(null);
			setCity(null);
			setAddressForm(false);
		}
	};

	return (
		<section className='section-stepGeoloc'>
			{error ? (
				<div style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
					<div className='card-scan' style={{ textAlign: 'center' }}>
						<h3 className='title-moduleInput' style={{ fontSize: '15px', width: '300px', marginTop: '5%', marginBottom: '5%', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
							Désolé, la géolocalisation n'a pas fonctionné...
						</h3>
						<img src={errorPic} className='insert-pic' alt='ajouter' />
						<h3 className='title-moduleInput' style={{ fontSize: '12px', width: '250px', marginTop: '5%', color: 'rgba(16, 16, 16, 0.6)', textShadow: '#9e9e9ed4 2px 2px 5px', textAlign: 'center', fontStyle: 'italic' }}>
							Vérifiez que les autorisations aient bien été accordées. Si vous connaissez l'adresse, vous pouvez aussi l'entrer directement.
						</h3>
					</div>

					<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
						<Button
							style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }}
							onClick={() => {
								setError(false);
							}}
							variant='contained'
							startIcon={<ReplyIcon />}>
							Revenir
						</Button>

						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<ReplayIcon />} onClick={geoloc}>
							Recommencer
						</Button>
					</div>
				</div>
			) : (
				<>
					{errorAddress ? (
						<div style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
							<div className='card-scan' style={{ textAlign: 'center' }}>
								<h3 className='title-moduleInput' style={{ fontSize: '15px', width: '300px', marginTop: '5%', marginBottom: '5%', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
									Désolé, nous ne trouvons pas l'adresse sélectionnée...
								</h3>
								<img src={errorPic} className='insert-pic' alt='ajouter' />
								<h3 className='title-moduleInput' style={{ fontSize: '12px', width: '250px', marginTop: '5%', color: 'rgba(16, 16, 16, 0.6)', textShadow: '#9e9e9ed4 2px 2px 5px', textAlign: 'center', fontStyle: 'italic' }}>
									Vérifiez que l'adresse est correcte. Si vous êtes proche du véhicule vous pouvez aussi essayer la géolocalisation.
								</h3>
							</div>

							<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
								<Button
									style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }}
									onClick={() => {
										setErrorAddress(false);
										setStreetNumber(null);
										setStreetName(null);
										setCity(null);
										setMore(null);
									}}
									variant='contained'
									startIcon={<ReplyIcon />}>
									Revenir
								</Button>

								<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<LocationOnIcon />} onClick={geoloc}>
									Géolocalisation
								</Button>
							</div>
						</div>
					) : (
						<>
							<div className='prez-geoloc'>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<div className='back-top-btn' style={{ marginRight: '15px' }} onClick={backTop}>
										<ReplyIcon />
									</div>

									<div className='progress-bar'>
										<div className='progress-done' style={style}>
											{text}
										</div>
									</div>
								</div>
								<h2 className='title-stepGeoloc' style={{ marginBottom: '5%', color: 'white', textShadow: '2px 2px 2px grey', textAlign: 'center', fontSize: '1.2rem' }}>
									LOCALISATION
								</h2>
							</div>

							{!addressForm ? (
								<>
									<div className='card-mini'>
										<h3 className='title-moduleInput' style={{ color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
											Pour la plaque
										</h3>
										<VisualPlate></VisualPlate>
									</div>

									<div className='card-mini'>
										{dataUri1 ? (
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
												<h3 className='title-moduleInput' style={{ color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
													Photos additionnelles
												</h3>

												<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
													{dataUri1 && <img style={{ width: '90px', borderRadius: '10px' }} src={dataUri1} alt='saisie 1' />}
													{dataUri2 && <img style={{ width: '90px', borderRadius: '10px' }} src={dataUri2} alt='saisie 2' />}
													{dataUri3 && <img style={{ width: '90px', borderRadius: '10px' }} src={dataUri3} alt='saisie 3' />}
												</div>
											</div>
										) : (
											<h3 className='title-moduleInput' style={{ color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
												Sans photos additionnelles
											</h3>
										)}
									</div>

									<div className='choice-loc' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-around', justifyContent: 'center' }}>
										<Button
											style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', width: '50%', marginRight: '10px' }}
											variant='contained'
											startIcon={<CreateIcon />}
											className='locBtn'
											onClick={() => {
												setAddressForm(true);
											}}>
											Adresse
										</Button>
										<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<LocationOnIcon />} className='geolocBtn' onClick={geoloc}>
											Géolocalisation
										</Button>
									</div>
								</>
							) : (
								<>
									<p style={{ color: 'white', textAlign: 'center', fontStyle: 'italic' }}>{errors.streetNumber && 'Veuillez remplir tous les champs'}</p>
									<form style={{ border: 'none', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} onSubmit={handleSubmit(onSubmit)}>
										<div className='card-scan' style={{ textAlign: 'center' }}>
											<img src={addressPic} alt='Inscription' style={{ width: '40%', marginBottom: '5%' }} />
											<div style={{ position: 'relative', display: 'flex', marginTop: '5%' }}>
												<Icon style={{ color: '#f47597', paddingTop: '5px', paddingBottom: '30px', marginRight: '15px' }}>control_camera</Icon>
												<input
													name='streetNumber'
													ref={register({ required: true })}
													onChange={(e) => {
														setStreetNumber(e.target.value);
													}}
													style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
												/>
												<label className={`${streetNumber ? 'label-address' : ''}  lab-address`}>Numéro de voie</label>
											</div>
											<div style={{ position: 'relative', display: 'flex', marginTop: '5%' }}>
												<Icon style={{ color: '#f47597', paddingTop: '5px', paddingBottom: '30px', marginRight: '15px' }}>location_on</Icon>
												<input
													name='streetName'
													ref={register({ required: true })}
													onChange={(e) => {
														setStreetName(e.target.value);
													}}
													style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
												/>
												<label className={`${streetName ? 'label-address' : ''} lab-address`}>Libellé de la voie</label>
											</div>
											<div style={{ position: 'relative', display: 'flex', marginTop: '5%' }}>
												<Icon style={{ color: '#f47597', paddingTop: '5px', paddingBottom: '30px', marginRight: '15px' }}>apartment</Icon>
												<input
													name='city'
													ref={register({ required: true })}
													onChange={(e) => {
														setCity(e.target.value);
													}}
													style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
												/>
												<label className={`${city ? 'label-address' : ''} lab-address`}>Ville</label>
											</div>
											<div style={{ position: 'relative', display: 'flex', marginTop: '5%' }}>
												<Icon style={{ color: '#f47597', paddingTop: '5px', paddingBottom: '30px', marginRight: '15px' }}>message</Icon>
												<textarea
													name='more'
													ref={register()}
													onChange={(e) => {
														setMore(e.target.value);
													}}
													style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
												/>
												<label className={`${more ? 'label-address' : ''} lab-address`}>Commentaire</label>
											</div>
										</div>

										<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
											<Button
												style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }}
												variant='contained'
												startIcon={<ReplyIcon />}
												onClick={() => {
													setStep('geoloc');
													setStreetNumber(null);
													setStreetName(null);
													setCity(null);
													setMore(null);
													setAddressForm(false);
												}}>
												Revenir
											</Button>

											<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<ReplayIcon />} type='submit'>
												Valider
											</Button>
										</div>
									</form>
								</>
							)}
						</>
					)}
				</>
			)}
		</section>
	);
}

export default StepGeoloc;
