import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

import logo from "../../assets/logo.png";
import wave from "../../assets/wave.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import PersonIcon from "@material-ui/icons/Person";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import Button from "@material-ui/core/Button";
import { LocalContext } from "../AddPlate/Context/LocalContext";

import { useAuthState } from "react-firebase-hooks/auth";
import { fireAuth } from "../../firebase";

const Header = () => {
	const [fireUser] = useAuthState(fireAuth);

	return (
		<>
			<Link to="/" style={{ textDecoration: "none" }}>
				<header>
					<div className="header">
						<img src={wave} className="wave" alt="wave" />
						<img src={logo} className="logo" alt="logo" />
					</div>
				</header>
			</Link>

			{!fireUser ? (
				<Link to="/login" style={{ textDecoration: "none" }}>
					<Button className="login" style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", position: "absolute", zIndex: "1", fontSize: "8px", top: "10px", right: "10px" }} variant="contained" size="small" startIcon={<PersonIcon />}>
					</Button>
				</Link>
			) : (
				<Link to="/user" style={{ textDecoration: "none" }}>
					<Button className="login" style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", position: "absolute", zIndex: "1", fontSize: "8px", top: "10px", right: "10px" }} variant="contained" size="small" startIcon={<HowToRegIcon />}>
					</Button>
				</Link>
			)}
						<Button data-href="https://www.watcher-app.com" style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", textDecoration: "none", color: "white", position: "absolute", zIndex: "1", fontSize: "8px", top: "10px", left: "10px" }} variant="contained" size="small" startIcon={<FacebookIcon />}>
				<a style={{ textDecoration: "none", color: "white", fontSize: "10px", textTransform: "capitalize" }} target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.watcher-app.com%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">
					Partager
				</a>
			</Button>
		</>
	);
};

export default Header;