import React, { useState, useEffect, useContext } from 'react';
import InsertCard from './InsertCard.js';
import SearchCard from './SearchCard.js';
import './Home.css';

import { fireAuth, fireStore } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { LocalContext } from '../AddPlate/Context/LocalContext.js';

function Home() {
	const [fireUser] = useAuthState(fireAuth);
	let users = fireStore.collection('users');
	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, authTokens, setAuthTokens, more, setMore, userName, setUserName] = useContext(LocalContext);

	useEffect(() => {
		if (fireUser) {
			users
				.where('mail', '==', `${fireUser.email}`)
				.get()
				.then((snapshot) => {
					snapshot.docs.map((doc) => {
						setUserName(doc.data().name);
					});
				});
		}
	}, [users]);

	return (
		<main className='home'>
			{userName ? (
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<div style={{ height: '30px', width: '250px' }}>
						<div className='progress-done' style={{ opacity: 1, width: '100%' }}>
							<p style={{ width: '80%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>Bienvenue {userName}</p>
						</div>
					</div>
				</div>
			) : (
				''
			)}
			<InsertCard />
			<SearchCard />
		</main>
	);
}

export default Home;
