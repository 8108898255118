import React, { useState, useContext, useEffect } from 'react';

import { PicContext } from '../Context/PicContext';
import { LocalContext } from '../Context/LocalContext';

import 'react-html5-camera-photo/build/css/index.css';
import Camera from 'react-html5-camera-photo';
import ImageUploading from 'react-images-uploading';
import Resizer from 'react-image-file-resizer';

import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import RedoIcon from '@material-ui/icons/Redo';
import VisualPlate from '../StepPlate/VisualPlate';
import ReplyIcon from '@material-ui/icons/Reply';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Edit from '@material-ui/icons/Edit';

function StepPics() {
	// eslint-disable-next-line
	const [dataUri1, setDataUri1, dataUri2, setDataUri2, dataUri3, setDataUri3, images, setImages, picType, setPicType, choice, setChoice] = useContext(PicContext);
	const [step, setStep, validPlate, setValidPlate, plate, setPlate] = useContext(LocalContext); // eslint-disable-line
	const [style, setStyle] = useState({});
	const [text, setText] = useState('');
	const maxNumber = 3;

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				200,
				200,
				'JPEG',
				50,
				0,
				(uri) => {
					resolve(uri);
				},
				'base64'
			);
		});

	const noPics = () => {
		setStep('geoloc');
		setValidPlate('');
		setChoice(true);
		setDataUri1('');
		setDataUri2('');
		setDataUri3('');
	};

	const fsPics = () => {
		setStep('pics');
		setChoice(true);
		setPicType('fsPics');
	};

	const camPics = () => {
		setStep('pics');
		setChoice(true);
		setPicType('camPics');
	};

	const picDone = () => {
		setStep('geoloc');
		setValidPlate('');
	};

	const picFsDone = async () => {
		if (images.length === 1) {
			try {
				const uri1 = await fetch(images[0]?.data_url);
				const blob1 = await uri1.blob();
				const image1 = await resizeFile(blob1);
				setDataUri1(image1);
			} catch (err) {
				console.log(err);
			}
		} else {
			if (images.length === 2) {
				try {
					const uri1 = await fetch(images[0]?.data_url);
					const blob1 = await uri1.blob();
					const image1 = await resizeFile(blob1);
					const uri2 = await fetch(images[1]?.data_url);
					const blob2 = await uri2.blob();
					const image2 = await resizeFile(blob2);
					setDataUri1(image1);
					setDataUri2(image2);
				} catch (err) {
					console.log(err);
				}
			} else {
				if (images.length === 3) {
					const uri1 = await fetch(images[0]?.data_url);
					const blob1 = await uri1.blob();
					const image1 = await resizeFile(blob1);
					const uri2 = await fetch(images[1]?.data_url);
					const blob2 = await uri2.blob();
					const image2 = await resizeFile(blob2);
					const uri3 = await fetch(images[2]?.data_url);
					const blob3 = await uri3.blob();
					const image3 = await resizeFile(blob3);
					setDataUri1(image1);
					setDataUri2(image2);
					setDataUri3(image3);
				}
			}
		}
		setStep('geoloc');
		setValidPlate('');
	};

	const picRedo = () => {
		setDataUri1('');
		setDataUri2('');
		setDataUri3('');
	};

	const savePic = async (dataUri) => {
		if (dataUri1 === '') {
			setDataUri1(dataUri);
		} else {
			if (dataUri2 === '') {
				setDataUri2(dataUri);
			} else {
				if (dataUri3 === '') {
					setDataUri3(dataUri);
				}
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setStyle({ opacity: 1, width: '66%' });
		}, 200);
		setTimeout(() => {
			setText('Étape 2/3');
		}, 1200);
	}, []);

	const backTop = () => {
		if (choice) {
			setChoice(false);
			setDataUri1('');
			setDataUri2('');
			setDataUri3('');
			setPicType('');
		} else {
			setStep(0);
			setPlate('');
		}
	};

	return (
		<section className='section-stepPics' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
			<div className='prez-pic' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<div className='back-top-btn' style={{ marginRight: '15px' }} onClick={backTop}>
						<ReplyIcon />
					</div>

					<div className='progress-bar'>
						<div className='progress-done' style={style}>
							{text}
						</div>
					</div>
				</div>
				<h1 className='title-stepPic' style={{ marginBottom: '5%', color: 'white', textShadow: '2px 2px 2px grey', textAlign: 'center' }}>
					PHOTOS ADDITIONNELLES
				</h1>
			</div>
			{!choice ? (
				<>
					<div className='module-input card'>
						<h1 className='title-moduleInput' style={{ marginTop: '5%', marginBottom: '5%', width: '260px', fontWeight: 'lighter', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
							Voulez-vous ajouter des photos pour accompagner le signalement de cette plaque ?
						</h1>
						<VisualPlate></VisualPlate>
					</div>

					<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center', gap: '3%' }}>
						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<RedoIcon />} onClick={noPics}>
							Passer
						</Button>
						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<PermMediaIcon />} onClick={fsPics}>
							Galerie
						</Button>
						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CameraAltIcon />} onClick={camPics}>
							Prendre
						</Button>
					</div>
				</>
			) : picType === 'camPics' ? (
				<div style={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
					{!dataUri3 ? (
						<div className='add-cam' style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
							<Camera style={{ borderRadius: '15px' }} onTakePhotoAnimationDone={savePic} idealFacingMode='environment' isImageMirror={false} sizeFactor={0.4} imageType='jpeg' />

							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
								{dataUri1 && <img style={{ width: '90px', borderRadius: '5px', marginRight: '1%' }} src={dataUri1} alt='pic1' />}
								{dataUri2 && <img style={{ width: '90px', borderRadius: '5px' }} src={dataUri2} alt='pic2' />}
							</div>
						</div>
					) : (
						<div style={{ textAlign: 'center' }}>
							<img style={{ width: '90px', borderRadius: '10px', boxShadow: '0px 0px 2px white', marginRight: '1%' }} src={dataUri1} alt='pic1' />
							<img style={{ width: '90px', borderRadius: '10px', boxShadow: '0px 0px 2px white' }} src={dataUri2} alt='pic2' />
							<img style={{ width: '90px', borderRadius: '10px', boxShadow: '0px 0px 2px white' }} src={dataUri3} alt='pic3' />
						</div>
					)}
				</div>
			) : (
				picType === 'fsPics' && (
					<div style={{ fontSize: '1px!important', color: '#ECB67B!important', textAlign: 'center' }}>
						<ImageUploading multiple value={images} onChange={(imageList) => setImages(imageList)} maxNumber={maxNumber} acceptType={['jpg', 'jpeg', 'webp', 'avif', 'tiff', 'gif', 'png']} dataURLKey='data_url'>
							{({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
								<>
									<Button onClick={onImageUpload} style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', height: '40px', marginBottom: '10%' }} variant='contained' startIcon={<PermMediaIcon />}>
										Ajouter
									</Button>
									<div className='upload__image-wrapper' style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
										{imageList.map((image, index) => (
											<div key={index} className='image-item' style={{ textAlign: 'center', borderRadius: '10px', boxShadow: '0px 0px 2px white', backgroundColor: 'white' }}>
												<img src={image['data_url']} alt='' width='100' />
												<div className='image-item__btn-wrapper' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
													<button onClick={() => onImageUpdate(index)} style={{ background: 'none', border: 'none', color: '#4a4a4a' }}>
														<Edit />
													</button>
													<button onClick={() => onImageRemove(index)} style={{ background: 'none', border: 'none', color: '#9d2929' }}>
														<HighlightOff />
													</button>
												</div>
											</div>
										))}
									</div>
									{images.length !== 0 && (
										<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
											<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }} variant='contained' startIcon={<RedoIcon />} onClick={onImageRemoveAll}>
												Reprendre
											</Button>
											<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CameraAltIcon />} onClick={picFsDone}>
												Valider
											</Button>
										</div>
									)}
								</>
							)}
						</ImageUploading>
					</div>
				)
			)}
			<div>
				{dataUri1 && (
					<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }} variant='contained' startIcon={<RedoIcon />} onClick={picRedo}>
							Reprendre
						</Button>

						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CameraAltIcon />} onClick={picDone}>
							Valider
						</Button>
					</div>
				)}
			</div>
		</section>
	);
}

export default StepPics;
