import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { LocalContext } from "../components/AddPlate/Context/LocalContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireAuth } from "../firebase";

function PrivateRoute({ component: Component, ...rest }) {
	// eslint-disable-next-line
	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, authTokens, setAuthTokens] = useContext(LocalContext); // eslint-disable-line
	const [fireUser] = useAuthState(fireAuth);

	return <Route {...rest} render={(props) => (fireUser ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />)} />;
}

export default PrivateRoute;
