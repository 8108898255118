import React, {useContext} from 'react';
import {LocalContext} from '../Context/LocalContext'

function VisualPlate() {
    // eslint-disable-next-line
    const [step, setStep, validPlate, setValidPlate, plate, setPlate] = useContext(LocalContext);

    return (
        <div className="visual-plate">
            <div className="plate">
                <div className="leftPlate"></div>
                <div className="plateNum">
                    {plate}
                </div>
                <div className="rightPlate"></div>
            </div>
        </div>
    )

}

export default VisualPlate
