import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

function Spinner() {

	return (
        <div>
            <div className="sweet-loading">
                <PuffLoader size={150} color={"#fff"} loading={true} />
            </div>
        </div>
	);
}

export default Spinner;