import React, { useState, createContext } from 'react';

export const PicContext = createContext();

export const DataPicContext = (props) => {
	const [dataUri1, setDataUri1] = useState('');
	const [dataUri2, setDataUri2] = useState('');
	const [dataUri3, setDataUri3] = useState('');
	const [images, setImages] = useState([]);
	const [picType, setPicType] = useState('');
	const [choice, setChoice] = useState(false);

	return <PicContext.Provider value={[dataUri1, setDataUri1, dataUri2, setDataUri2, dataUri3, setDataUri3, images, setImages, picType, setPicType, choice, setChoice]}>{props.children}</PicContext.Provider>;
};
