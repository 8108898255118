import React, { useState, useContext } from "react";

import Camera from "react-html5-camera-photo";
import { LocalContext } from "../Context/LocalContext";
import PuffLoader from "../../Spinner";
import errorPic from "../../../assets/error.png";
import VisualPlate from "../StepPlate/VisualPlate";
import Button from "@material-ui/core/Button";
import ReplyIcon from "@material-ui/icons/Reply";
import CheckIcon from "@material-ui/icons/Check";
import ReplayIcon from "@material-ui/icons/Replay";

import { fireStore } from "../../../firebase";

function StepScan() {
	// eslint-disable-next-line
	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress] = useContext(LocalContext);
	const [picScan, setPicScan] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [display, setDisplay] = useState(false);
	const [plateExists, setPlateExists] = useState(false);

	let plates = fireStore.collection("plates");

	const plateValidation = () => {
		if (plate !== "") {
			plates.where("num", "==", plate).onSnapshot((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				if (data.length === 0) {
					setValidPlate(1);
					setStep("pics");
					setLoading(false);
				} else {
					setPlateExists(true);
					setLoading(false);
				}
			});
		} else setValidPlate(0);
	};

	const redo = () => {
		setPicScan(false);
		setError(false);
		setPlate("");
		setDisplay(false);
	};

	const back = () => {
		setStep(0);
		setPicScan(false);
		setError(false);
		setPlate("");
		setDisplay(false);
	};

	function scanPic(dataUri) {
		setLoading(true);
		setPicScan(true);
		const myHeaders = new Headers();
		myHeaders.append("apikey", "739498b88588957");
		myHeaders.append("Accept", "image / png");

		const formdata = new FormData();
		formdata.append("language", "fre");
		formdata.append("isOverlayRequired", "false");
		formdata.append("filetype", "png");
		formdata.append("base64Image", dataUri);
		formdata.append("iscreatesearchablepdf", "false");
		formdata.append("issearchablepdfhidetextlayer", "false");
		formdata.append("OCREngine", "2");

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch("https://api.ocr.space/parse/image", requestOptions)
			.then((response) => response.text())
			.then(async (result) => {
				const text = JSON.parse(result).ParsedResults[0].ParsedText;
				const titanium = text.match(/[A-Z]{1,2}.?[0-9]{1,3}.?[A-Z]{1,2}/s);
				if (text === "" || text === undefined) {
					setLoading(false);
					setError(true);
				} else {
					setLoading(false);
					const formattedText = titanium[0]
						.replace(/[^a-zA-Z]/, "")
						.replace(/[O]/, "Q")
						.replace(/[-]/g, "")
						.replace(/[_]/g, "")
						.replace(/\s/g, "");
					const zone1 = formattedText.slice(0, 2);
					const zone2 = formattedText.slice(2, 5);
					const zone3 = formattedText.slice(5, 7);
					setPlate(zone1 + "-" + zone2 + "-" + zone3);
					setDisplay(true);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setError(true);
			});
	}

	return (
		<section className="section-stepScan" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
			<div className="prez-input" style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
				<div className="progress-bar">
					<div className="progress-done" style={{ opacity: 1, width: "33%" }}>
						Étape 1/3
					</div>
				</div>
				<h1 className="title-stepInput" style={{ color: "white", textShadow: "2px 2px 2px grey", textAlign: "center" }}>
					PLAQUE D'IMMATRICULATION
				</h1>
			</div>

			{!picScan ? (
				<div style={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
					<div className="card-scan" style={{ textAlign: "center" }}>
						<h3 className="title-moduleInput" style={{ width: "300px", marginTop: "5%", marginBottom: "5%", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
							Scannez la plaque
						</h3>

						<div className="plate">
							<div className="leftPlate"></div>
							<div className="scan-cam" style={{ width: "100%" }}>
								<Camera onTakePhotoAnimationDone={scanPic} idealFacingMode="environment" isImageMirror={false} />
							</div>
							<div className="rightPlate"></div>
						</div>
					</div>

					<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", width: "50%", marginTop: "25px" }} variant="contained" startIcon={<ReplyIcon />} onClick={back}>
						Annuler
					</Button>
				</div>
			) : (
				<div>
					{loading && (
						<div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
							<div style={{ width: "100%" }}>
								<h1 className="title-stepInput" style={{ color: "white", textShadow: "2px 2px 2px grey", textAlign: "center" }}>
									Scan en cours...
								</h1>
							</div>
							<div style={{ width: "100%" }}>
								<PuffLoader />
							</div>
						</div>
					)}
					{display && (
						<>
							{plateExists ? (
								<>
									<p style={{ color: "white", textAlign: "center", fontStyle: "italic" }}>Cette plaque est déjà signalée</p>

									<div className="choice-pic" style={{ marginTop: "10%", display: "flex", alignItems: "space-between", justifyContent: "center" }}>
										<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", width: "50%", marginRight: "10px" }} variant="contained" startIcon={<ReplyIcon />} onClick={back}>
											Revenir
										</Button>

										<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" startIcon={<CheckIcon />} onClick={redo}>
											Reprendre
										</Button>
									</div>
								</>
							) : (
								<>
									<div className="card-scan" style={{ textAlign: "center", minHeight: "200px" }}>
										<h3 className="title-moduleInput" style={{ width: "300px", marginTop: "5%", marginBottom: "5%", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
											Vérification de la plaque
										</h3>
										<VisualPlate />
									</div>

									<div className="choice-pic" style={{ marginTop: "10%", display: "flex", alignItems: "space-between", justifyContent: "center" }}>
										<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", marginRight: "10px" }} variant="contained" startIcon={<ReplayIcon />} onClick={redo}>
											Reprendre
										</Button>

										<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" startIcon={<CheckIcon />} onClick={plateValidation}>
											Valider
										</Button>
									</div>
								</>
							)}
						</>
					)}
				</div>
			)}

			{error && (
				<div style={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
					<div className="card-scan" style={{ textAlign: "center" }}>
						<h3 className="title-moduleInput" style={{ fontSize: "15px", width: "300px", marginTop: "5%", marginBottom: "5%", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
							Désolé, l'image n'a pas pu être traitée correctement
						</h3>
						<img src={errorPic} className="insert-pic" alt="ajouter" />
					</div>

					<div className="choice-pic" style={{ marginTop: "10%", display: "flex", alignItems: "space-between", justifyContent: "center" }}>
						<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", width: "50%", marginRight: "10px" }} variant="contained" startIcon={<ReplyIcon />} onClick={back}>
							Revenir
						</Button>

						<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" startIcon={<ReplayIcon />} onClick={redo}>
							Recommencer
						</Button>
					</div>
				</div>
			)}
		</section>
	);
}

export default StepScan;
