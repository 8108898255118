import React, { useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { LocalContext } from '../AddPlate/Context/LocalContext';
import dayjs from 'dayjs';
import './SearchPlate.css';

import { fireAuth, fireStore, googleProvider, facebookProvider } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import Button from '@material-ui/core/Button';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import signinPic from '../../assets/unlock.png';
import signinGoogle from '../../assets/google-connect.png';
import signinFacebook from '../../assets/facebook-connect.png';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import CheckIcon from '@material-ui/icons/Check';

function Login(props) {
	const [fireUser] = useAuthState(fireAuth);
	let users = fireStore.collection('users');

	const auth = getAuth();

	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, authTokens, setAuthTokens] = useContext(LocalContext); // eslint-disable-line

	const [mail, setMail] = useState('');
	const [password, setPassword] = useState('');
	const [noMatch, setNoMatch] = useState(false);
	const [forgotPass, setForgotPass] = useState(false);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState('');

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	async function login(e) {
		e.preventDefault();
		fireAuth.signInWithEmailAndPassword(mail, password).catch((err) => {
			console.log(err);
			setNoMatch(true);
		});
	}

	if (fireUser) {
		return <Redirect to='chercher-plaque' />;
	}

	const signInWithGoogle = () => {
		fireAuth
			.signInWithPopup(googleProvider)
			.then((created) => {
				users.add({
					userID: created.user.uid,
					name: created.user.displayName,
					mail: created.user.email,
					follow: 'none',
					date: dayjs().format('DD/MM/YYYY'),
				});
			})
			.catch((err) => setError(`Désolé, la connexion n'a pas pu se réaliser correctement`));
	};

	const signInWithFacebook = () => {
		fireAuth
			.signInWithPopup(facebookProvider)
			.then((created) => {
				users.add({
					userID: created.user.uid,
					name: created.user.displayName,
					mail: created.user.email,
					follow: 'none',
					date: dayjs().format('DD/MM/YYYY'),
				});
			})
			.catch((err) => setError(`Désolé, la connexion n'a pas pu se réaliser correctement`));
	};

	const forgottenPassword = () => {
		console.log('first');
		sendPasswordResetEmail(auth, mail)
			.then(setForgotPass(true))
			.catch((err) => {
				setError('Désolé, la réinitialisation de votre mot de passe à échouée');
				console.log(err);
			});

		setOpen(false);
		console.log('second');
	};

	return (
		<section className='section-login' style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
			<div className='prez-done'>
				<div className='progress-bar'>
					<div className='progress-done' style={{ opacity: 1, width: `100%` }}>
						Connectez-vous
					</div>
				</div>
			</div>

			{fireUser && console.log(fireUser)}
			{noMatch && <p className='warning'>Ces identifiants n'ont pas permis de vous reconnaître</p>}
			{forgotPass && <p className='warning'>Un email vous a été envoyé</p>}
			{error && <p className='warning'>{error}</p>}

			<div className='card-scan'>
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '5%', cursor: 'pointer' }} onClick={signInWithGoogle}>
					<img src={signinGoogle} alt='Connect with Google' style={{ width: '150px' }} />
				</div>
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '5%', cursor: 'pointer' }} onClick={signInWithFacebook}>
					<img src={signinFacebook} alt='Connect with Facebook' style={{ width: '150px' }} />
				</div>
			</div>

			<div className='card-scan' style={{ textAlign: 'center' }}>
				<form noValidate>
					<img src={signinPic} alt='Inscription' style={{ width: '0%', marginBottom: '25px' }} />
					<div style={{ position: 'relative' }}>
						<Icon style={{ color: '#f47597', paddingTop: '5px', marginRight: '15px' }}>mail</Icon>
						<input
							type='email'
							value={mail}
							onChange={(e) => {
								setMail(e.target.value);
							}}
							required
							style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
						/>
						<label className={mail && `label-mail`}>Adresse Email</label>
					</div>
					<div style={{ position: 'relative', marginTop: '15px' }}>
						<Icon style={{ color: '#f47597', paddingTop: '5px', marginRight: '15px' }}>https</Icon>
						<input
							type='password'
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							required
							style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
						/>
						<label className={password && `label-password`}>Mot de Passe</label>
					</div>
					<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginTop: '15px' }} variant='contained' size='small' startIcon={<HowToRegIcon />} onClick={login}>
						Se connecter
					</Button>
				</form>
			</div>

			<Link to='/signup' style={{ textDecoration: 'none', color: 'white', fontSize: '14px' }}>
				Pas encore de compte ?
			</Link>
			<p style={{ textDecoration: 'none', color: 'white', fontSize: '14px' }} onClick={handleOpen}>
				Mot de passe oublié
			</p>

			<Modal open={open} onClose={handleClose} style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className='card-modal'>
					<div className='progress-done' style={{ opacity: 1, width: `200px`, height: '40px', marginBottom: '40px', padding: '0 10px 0 10px', fontSize: '15px' }}>
						<Icon style={{ color: 'white', marginRight: '10px' }}>warning</Icon>Action Définitive<Icon style={{ color: 'white', marginLeft: '10px' }}>warning</Icon>
					</div>

					<div>
						<div style={{ marginBottom: '40px' }}>
							<p style={{ fontSize: '12px', color: '#404040e0', marginBottom: '20px' }}>Nous allons vous envoyer les instructions à suivre afin de réinitialier votre mot de passe sur votre adresse mail de référence :</p>
							<div style={{ position: 'relative', display: 'flex', marginBottom: '20px' }}>
								<Icon style={{ color: '#f47597', paddingTop: '5px', marginLeft: '10px' }}>mail</Icon>
								<input
									type='email'
									value={mail}
									onChange={(e) => {
										setMail(e.target.value);
									}}
									required
									style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
								/>
								<label className={mail && `label-mail`}>Adresse Email</label>
							</div>
							<p style={{ fontSize: '12px', color: '#404040e0', marginBottom: '20px' }}>Si vous confirmez cette action, vous perdez votre mot de passe actuel. </p>
							<p style={{ fontSize: '12px', color: '#404040e0', marginBottom: '20px' }}>Êtes-vous bien sûr de votre choix ?</p>
						</div>
					</div>

					<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CheckIcon />} size='small' className='DoneBtn' onClick={forgottenPassword}>
						Je Confirme
					</Button>
				</div>
			</Modal>
		</section>
	);
}

export default Login;