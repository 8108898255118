import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { LocalContext } from "../AddPlate/Context/LocalContext";
import dayjs from "dayjs";
import "./SearchPlate.css";

import signupPic from "../../assets/shield.png";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Dialog from "@material-ui/core/Dialog";
import CheckIcon from "@material-ui/icons/Check";
import PuffLoader from "../Spinner";

import { fireAuth, fireStore } from "../../firebase";

function Signup() {
	// eslint-disable-next-line
	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, authTokens, setAuthTokens] = useContext(LocalContext); // eslint-disable-line
	const [username, setUsername] = useState("");
	const [mail, setMail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordBis, setPasswordBis] = useState("");
	const [isError, setIsError] = useState(""); // eslint-disable-line
	const [passCheck, setPassCheck] = useState(false);
	const [nameCheck, setNameCheck] = useState(false);
	const [mailCheck, setMailCheck] = useState(false);
	const [badUsername, setBadUsername] = useState(false);
	const [badMail, setBadMail] = useState(false);
	const [passShort, setPassShort] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);
	const [cgu, setCgu] = useState(false);
	const [open, setOpen] = useState(false);
	const [names, setNames] = useState([]);
	const [mails, setMails] = useState([]);
	const [loading, setLoading] = useState(false);

	let users = fireStore.collection("users");
	const history = useHistory();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		users.get().then((snapshot) => {
			snapshot.docs.map((doc) => {
				mails.push(doc.data().mail);
				names.push(doc.data().name);
			});
		});
	}, [users]);

	async function signUp(e) {
		e.preventDefault();
		if (username !== "" && password !== "" && mail !== "" && cgu !== false) {
			setIsEmpty(false);
			if (/^[A-Za-z ]+$/.test(username)) {
				setBadUsername(false);
				if (!names.includes(username)) {
					setNameCheck(false);
					if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(mail)) {
						setBadMail(false);
						if (!mails.includes(mail)) {
							if (password.length > 5) {
								setPassShort(false);
								if (password === passwordBis) {
									setPassCheck(false);
									setLoading(true);
									fireAuth
										.createUserWithEmailAndPassword(mail, password)
										.then((created) => {
											users
												.add({
													userID: created.user.uid,
													name: username,
													mail: mail,
													follow: "none",
													date: dayjs().format("DD/MM/YYYY"),
												})
												.then(history.push("/"));
											/* window.emailjs
												.send("gmail", "template_XGUHFWfl", { to_name: username, to_mail: mail })
												.then(console.log('success mail sent'))
												.catch((err) => console.error("le mail n'a pu être envoyé:", err)); */
										})
										.catch((err) => {
											console.log("Oups... : " + err);
											setIsError("Désolé, un problème est survenu. Veuillez réessayer");
											setLoading(false);
										});
								} else {
									setPassCheck(true);
								}
							} else {
								setPassShort(true);
							}
						} else setMailCheck(true);
					} else {
						setBadMail(true);
					}
				} else setNameCheck(true);
			} else {
				setBadUsername(true);
			}
		} else {
			setIsEmpty(true);
		}
	}

	return (
		<>
			{!loading ? (
				<section className="section-login" style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
					<div className="prez-done">
						<div className="progress-bar">
							<div className="progress-done" style={{ opacity: 1, width: `100%` }}>
								Création de compte
							</div>
						</div>
					</div>
					{isEmpty ? <p className="warning">Un ou plusieurs champs n'ont pas été rempli</p> : <>{passCheck ? <p className="warning">Les mots de passes ne correspondent pas</p> : <>{nameCheck ? <p className="warning">Cet identifiant existe déjà</p> : <>{mailCheck ? <p className="warning">Cette adresse email est déjà utilisée</p> : <>{badUsername ? <p className="warning">Pas de chiffres ni de caractères spéciaux</p> : <>{badMail ? <p className="warning">Le format de l'adresse email n'est pas valide</p> : <>{passShort && <p className="warning">Votre mot de passe est trop court</p>}</>}</>}</>}</>}</>}</>}
					{isError && <p className="warning">{isError}</p>}
					<div className="card-scan" style={{ textAlign: "center" }}>
						<form style={{ border: "none" }} noValidate>
							<img src={signupPic} alt="Inscription" style={{ width: "40%" }} />
							<div style={{ position: "relative" }}>
								<Icon style={{ color: "#f47597", paddingTop: "5px", marginRight: "15px", paddingBottom: "24px" }}>person</Icon>
								<input
									type="username"
									value={username}
									onChange={(e) => {
										setUsername(e.target.value);
									}}
									className={badUsername ? `bad-input` : ""}
									required
									style={{ height: "40px", border: "none", paddingLeft: "5px", color: "#000000a3", fontWeight: "bold" }}
								/>
								<label className={username ? `label-username` : ""} id={badUsername ? `bad-username` : ""}>
									Nom et Prénom
								</label>
							</div>
							<div style={{ position: "relative" }}>
								<Icon style={{ color: "#f47597", paddingTop: "5px", marginRight: "15px", paddingBottom: "24px" }}>mail</Icon>
								<input
									type="email"
									value={mail}
									onChange={(e) => {
										setMail(e.target.value);
									}}
									className={badMail ? `bad-input` : ""}
									required
									style={{ height: "40px", border: "none", paddingLeft: "5px", color: "#000000a3", fontWeight: "bold" }}
								/>
								<label className={mail ? `label-mail` : ""} id={badMail ? `bad-mail` : ""}>
									Adresse Email
								</label>
							</div>
							<div style={{ position: "relative" }}>
								<Icon style={{ color: "#f47597", paddingTop: "5px", marginRight: "15px", paddingBottom: "24px" }}>https</Icon>
								<input
									type="password"
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									className={passCheck ? `bad-input` : ""}
									required
									style={{ height: "40px", border: "none", paddingLeft: "5px", color: "#000000a3", fontWeight: "bold" }}
								/>
								<label className={password ? `label-password` : ""} id={passCheck ? `bad-password` : ""}>
									Mot de Passe
								</label>
							</div>
							<div style={{ position: "relative" }}>
								<Icon style={{ color: "#f47597", paddingTop: "5px", marginRight: "15px", paddingBottom: "24px" }}>verified_user</Icon>
								<input
									type="password"
									value={passwordBis}
									onChange={(e) => {
										setPasswordBis(e.target.value);
									}}
									className={passCheck ? `bad-input` : ""}
									required
									style={{ height: "40px", border: "none", paddingLeft: "5px", color: "#000000a3", fontWeight: "bold" }}
								/>
								<label className={passwordBis ? `label-passwordBis` : ""} id={passCheck ? `bad-passwordBis` : ""}>
									Confirmation
								</label>
							</div>
							<div style={{ position: "relative", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "10%" }}>
								<input
									type="checkbox"
									style={{ height: "30px" }}
									onChange={() => {
										setCgu((prev) => !prev);
									}}
									value={cgu}
									checked={cgu}
									required
								/>
								<p data="checkbox" style={{ fontSize: "10px", fontStyle: "italic", width: "100%", left: "0", marginLeft: "15px" }} onClick={handleOpen}>
									J'ai pris connaissance et accepte <span style={{ fontWeight: "bolder", textShadow: "1px 1px 1px rgba(0,0,0, 0.1)" }}>les Conditions Générales d'Utilisation</span>
								</p>
							</div>
							<Dialog open={open} onClose={handleClose}>
								<div style={{ padding: "5%", display: "flex", flexDirection: "column", alignItems: "center" }}>
									<div className="progress-done" style={{ opacity: 1, width: `fit-content`, textAlign: "center", marginBottom: "40px", padding: "0 10px 0 10px", fontSize: "15px" }}>
										Conditions générales d'utilisation WATCHER
									</div>

									<div>
										<div style={{ marginBottom: "40px" }}>
											<div style={{ fontSize: "12px", color: "#404040e0", marginBottom: "20px", textAlign: "justify" }}>
												Le présent document a pour objet de définir les modalités et conditions dans lesquelles d’une part, WATCHER, ci-après dénommé l’EDITEUR, met à la disposition de ses utilisateurs l'application, et les services disponibles sur l'application et d’autre part, la manière par laquelle l’utilisateur accède à l'application et utilise ses services.
												<br />
												<br />
												Toute connexion à l'application est subordonnée au respect des présentes conditions.
												<br />
												<br /> Pour l’utilisateur, le simple accès à l'application de l’EDITEUR implique l’acceptation de l’ensemble des conditions décrites ci-après.
											</div>
											<div style={{ fontSize: "12px", color: "#404040e0", textAlign: "justify" }}>
												<h3>1. Conditions d’accès et de création d’un espace personnel.</h3>
												<br />
												<h4>1.1. Condition d'accès</h4>
												<br />
												L'EDITEUR s’efforce de permettre l’accès à l'application 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d’un événement hors du contrôle de l'EDITEUR, et sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement d'une application et des services. <br />
												<br /> Par conséquent, l'EDITEUR ne peut garantir une disponibilité à l'application et des services associés, une fiabilité des transmissions et des performances en termes de temps de réponse ou de qualité. Il n’est prévu aucune assistance technique vis à vis de l’UTILISATEUR que ce soit par des moyens électronique ou téléphonique. <br />
												<br /> La responsabilité de l'EDITEUR ne saurait être engagée en cas d’impossibilité d’accès à cette application et/ou d’utilisation des services. <br />
												<br /> Par ailleurs, l'EDITEUR peut être amené à interrompre l'application ou une partie des services, à tout moment sans préavis, le tout sans droit à indemnités. L’UTILISATEUR reconnaît et accepte que l'EDITEUR ne soit pas responsable des interruptions, et des conséquences qui peuvent en découler pour l’UTILISATEUR ou tout tiers. <br />
												<br />
												<h4>1.2. Création d'un espace personnel</h4>
												<br />
												Un espace personnel est destiné aux personnes à la recherche d'informations concernant la localisation de leur véhicule. Toute connexion à l’Espace Personnel est subordonnée au respect des présentes conditions générales d’utilisation. L’UTILISATEUR reconnaît à ce titre que le simple accès à l’Espace Personnel implique l’acceptation de l’ensemble des conditions décrites ci-après. <br />
												<br />
												<h3>2. Description des services mis à disposition.</h3>
												<br />
												<h4>2.1. L'utilisateur</h4>
												<br />
												L'application a pour objet à aider la localisation d'un véhicule en cas de perte ou vol par le biais de particuliers via l'enregistrement d'une plaque d'immatriculation et optionnellement l'intégration de photos du véhicule pour informer de son état au moment de la prise de vue. <br />
												<br />
												Après création d'un compte personnel, l'UTILISATEUR, à la recherche de son véhicule, pourra connaitre sa localisation qui correspondra à la date de la transmission de l'information par les particuliers ainsi que les modalités à entreprendre pour récupérer son véhicule auprès des institutions compétentes, sous condition que la plaque d'immatriculation soit enregistrée dans la base de données. <br />
												<br />
												<h4>2.2. Le particulier</h4>
												<br />
												Sans créer de compte, le particulier pourra transmettre des informations sur un véhicule qui lui paraitrait abandonné. La transmission de l'information est anonyme est l'UTILISATEUR n'aura aucun moyen de connaitre l'identité des PARTICULIERS qui participent à la transmission des informations concernant la localisation des véhicules. <br />
												<br /> <h3>3. Droits de l'EDITEUR.</h3>
												<br /> L'EDITEUR se réserve la possibilité de modifier, à tout moment et sans préavis, les présentes conditions d’utilisation afin de les adapter aux évolutions d'application et/ou de son exploitation. <br />
												<br /> Les informations sont susceptibles de contenir des inexactitudes techniques et des erreurs typographiques. <br />
												<br /> L'EDITEUR se réserve le droit de les corriger, dès que ces erreurs sont portées à sa connaissance. <br />
												<br />
												Il est fortement recommandé de vérifier l’exactitude et la pertinence des informations mis à disposition sur cette application. <br />
												<br /> Les informations disponibles sur cette application sont susceptibles d’être modifiés à tout moment et peuvent avoir fait l’objet de mises à jour. En particulier, ils peuvent avoir fait l’objet d’une mise à jour entre le moment de leur téléchargement et celui où l’UTILISATEUR en prend connaissance. <br />
												<br /> L’utilisation des informations disponibles sur cette application se fait sous l’entière et seule responsabilité de l’UTILISATEUR, qui assume la totalité des conséquences pouvant en découler, sans que l'EDITEUR puisse être recherché à ce titre, et sans recours contre ce dernier. <br />
												<br /> L'EDITEUR ne pourra en aucun cas être tenu responsable de tout dommage de quelque nature qu’il soit résultant de l’interprétation ou de l’utilisation des informations et/ou documents disponibles sur cette application. <br />
												<br /> <h3>4. Droits, obligations et responsabilités de l'utilisateur.</h3>
												<br /> <h4>4.1. Droits</h4>
												<br />
												Tant la présente application que les modalités et conditions de son utilisation sont régis par le droit français, quel que soit le lieu d’utilisation. En cas de contestation éventuelle, et après l’échec de toute tentative de recherche d’une solution amiable, les tribunaux français seront seuls compétents pour connaître de ce litige. <br />
												<br /> <h4>4.2. Obligations et responsabilités</h4>
												<br /> L’UTILISATEUR déclare accepter les caractéristiques et les limites d’Internet, et notamment reconnaît que : <br />
												<br /> L'EDITEUR n’assume aucune responsabilité sur les services accessibles par Internet et n’exerce aucun contrôle de quelque forme que ce soit sur la nature et les caractéristiques des données qui pourraient transiter par l’intermédiaire de son centre serveur. <br />
												<br /> L’UTILISATEUR reconnaît que les données circulant sur Internet ne sont pas protégées notamment contre les détournements éventuels. La présence du logo WATCHER institue une présomption simple de validité. La communication de toute information jugée par l’UTILISATEUR de nature sensible ou confidentielle se fait à ses risques et périls. <br />
												<br /> L’UTILISATEUR reconnaît que les données circulant sur Internet peuvent être réglementées en termes d’usage ou être protégées par un droit de propriété. <br />
												<br /> L’UTILISATEUR est seul responsable de l’usage des données qu’il consulte, interroge et transfère sur Internet. L’UTILISATEUR reconnaît que l'EDITEUR ne dispose d’aucun moyen de contrôle sur le contenu des services accessibles sur Internet. <br />
												<br /> <h3>5. Propriété intellectuelle</h3>
												<br /> La structure générale de l'application WATCHER, ainsi que les textes, graphiques, images, sons et vidéos la composant, sont la propriété de l'éditeur ou de ses partenaires. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des contenus et services proposés par l'application WATCHER, par quelque procédé que ce soit, sans l'autorisation préalable et par écrit de WATCHER est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L 335-2 et suivants du Code de la propriété intellectuelle. <br />
												<br /> La marque WATCHER est une marque déposée par TOKI Compagny. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de ces marques, de quelque nature que ce soit, est totalement prohibée. <br />
												<br /> <h3>6. Politique de confidentialité</h3>
												<br /> <h4>6.1. Informations personnelles</h4>
												<br /> Lors de la création de votre compte vous fournissez à WATCHER votre nom prénom et adresse mail, lors de la saisi d'une plaque d'immatriculation WATCHER aura alors connaissance de la catégorie de véhicule en votre possession. <br />
												<br /> <h4>6.2. Géolocalisation</h4>
												<br /> Dans le cadre de la saisie d'une plaque d'immatriculation WATCHER vous demandera l'autorisation de recueillir votre géolocalisation précise (latitude et longitude) notamment par GPS afin d'informer l'UTILISATEUR sur la localisation de son véhicule lors de sa recherche. La collecte de votre géolocalisation peut se faire en arrière-plan, même lorsque vous n'utilisez pas les services, si l'autorisation donnée le permet. Si vous refusez l'autorisation de collecter votre géolocalisation, nous ne la collecterons pas. <br />
												<br />
												<h4>6.3. Partage des informations</h4>
												<br /> <h5>a. Professionnels du secteur</h5>
												<br /> Afin d'optimiser la transmission et la gestion des informations relatives à la déclaration de véhicules volés certaines informations pourraient être partagées avec les professionnels dans le secteur de véhicules motorisés (Assurances, institutions publiques etc.). <br />
												<br /> <h5>b. Prestataires de services</h5>
												<br /> Nous pouvons également partager des informations avec des partenaires qui distribuent et nous aident dans la promotion de nos services. Par exemple, nous pouvons partager des informations limitées à votre sujet sous une forme hachée, illisible pour les humains avec nos partenaires publicitaires.
											</div>
										</div>
									</div>
									<Button
										style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", textAlign: "center" }}
										variant="contained"
										startIcon={<CheckIcon />}
										size="small"
										className="DoneBtn"
										onClick={() => {
											setCgu(true);
											handleClose();
										}}>
										J'accepte
									</Button>
								</div>
							</Dialog>

							<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" size="small" startIcon={<PersonAddIcon />} onClick={signUp}>
								S'inscrire
							</Button>
						</form>
					</div>

					<Link to="/login" style={{ textDecoration: "none", color: "white", fontSize: "14px" }}>
						Déjà inscris ?
					</Link>
				</section>
			) : (
				<PuffLoader />
			)}
		</>
	);
}

export default Signup;