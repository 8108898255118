import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './SearchPlate.css';

import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import errorPic from '../../assets/error.png';
import PuffLoader from '../Spinner';

import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import ReplyIcon from '@material-ui/icons/Reply';
import CheckIcon from '@material-ui/icons/Check';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import InfoIcon from '@material-ui/icons/Info';

import { fireStore } from '../../firebase';

function SearchPlate() {
	//const [step, setStep] = useState("");
	const [zone1, setZone1] = useState('');
	const [zone2, setZone2] = useState('');
	const [zone3, setZone3] = useState('');
	const [selected, setSelected] = useState(false);
	const [pic1, setPic1] = useState('');
	const [pic2, setPic2] = useState('');
	const [pic3, setPic3] = useState('');
	const [status, setStatus] = useState('');
	const [address, setAddress] = useState(false);
	const [more, setMore] = useState(null);
	const [lat, setLat] = useState('');
	const [long, setLong] = useState('');
	const [date, setDate] = useState('');
	const [validPlate, setValidPlate] = useState('');
	const [search, setSearch] = useState(false);
	const [plate, setPlate] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [openInfo, setOpenInfo] = useState(false);
	const [open, setOpen] = useState(false);
	const [doc, setDoc] = useState('');

	let plates = fireStore.collection('plates');

	const handleOpenInfo = () => {
		setOpenInfo(true);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenInfo(false);
	};

	const redo = () => {
		setSearch(false);
		setValidPlate('');
		setLoading(false);
		setPlate('');
	};

	const jobDone = async () => {
		setSearch(false);
		setValidPlate('');
		setLoading(false);
		setPlate('');

		plates.doc(doc).update({ status: 'watched' });
	};

	const plateValidation = () => {
		if ((zone1.length === 2) & (zone2.length === 3) & (zone3.length === 2)) {
			setSearch(true);
			setValidPlate(1);
			setLoading(true);
			plates
				.where('num', '==', `${zone1}-${zone2}-${zone3}`)
				.get()
				.then((querySnapshot) => {
					const data = querySnapshot.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}));
					if (data.length > 0) {
						fetch(`https://api.opencagedata.com/geocode/v1/json?key=9b58345250c04bd28d80090c442dacca&q=${data[0].long}+${data[0].lat}`)
							.then((res) => res.text())
							.then((geo) => {
								let obj = JSON.parse(geo);
								setAddress(obj.results[0].formatted);
							})
							.catch((error) => {
								setError(true);
								console.log(error);
							});
						setDoc(data[0].id);
						setPlate(data[0].num);
						setLat(parseFloat(data[0].long));
						setLong(parseFloat(data[0].lat));
						setPic1(data[0].pic1);
						setPic2(data[0].pic2);
						setPic3(data[0].pic3);
						setStatus(data[0].status);
						setDate(data[0].date);
						setMore(data[0].more);
						setLoading(false);
					} else {
						setLoading(false);
						setError(true);
					}
				})
				.catch((error) => {
					setLoading(false);
					setError(true);
					console.log(error);
				});
		} else {
			setValidPlate('error');
		}
	};

	const handleZone1 = (zone1) => {
		const formattedZone1 = zone1.replace(/[^a-zA-Z]/, '').toUpperCase();
		setZone1(formattedZone1);
		if (zone1.length === 2) {
			input2.current.focus();
		}
	};

	const handleZone2 = (zone2) => {
		const formattedZone2 = zone2.replace(/\D+/, '');
		setZone2(formattedZone2);
		if (zone2.length === 3) {
			input3.current.focus();
		}
	};

	const handleZone3 = (zone3) => {
		const formattedZone3 = zone3.replace(/[^a-zA-Z]/, '').toUpperCase();
		setZone3(formattedZone3);
	};

	const input2 = useRef();
	const input3 = useRef();

	return (
		<section className='section-search' style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
			{!search ? (
				<>
					<div className='prez-input' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
						<div className='progress-bar'>
							<div className='progress-done' style={{ opacity: 1, width: `100%` }}>
								Chercher un Véhicule
							</div>
						</div>
					</div>

					{validPlate === 'error' && <p style={{ color: 'white', textAlign: 'center', fontStyle: 'italic' }}>Veuillez vérifier que tous les champs soient bien remplis</p>}

					<div className='module-input card'>
						<h3 className='title-moduleInput' style={{ width: '300px', marginTop: '15px', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
							Tapez ici le numéro de plaque
						</h3>

						<div className='plate'>
							<div className='leftPlate'></div>
							<div className='plateNum'>
								<input type='text' className='zone1' placeholder='AA' value={zone1} onChange={(e) => handleZone1(e.target.value)} maxLength='2' required />
								<input type='text' ref={input2} className='zone2' placeholder='123' value={zone2} onChange={(e) => handleZone2(e.target.value)} maxLength='3' required />
								<input type='text' ref={input3} className='zone3' placeholder='AA' value={zone3} onChange={(e) => handleZone3(e.target.value)} maxLength='2' required />
							</div>
							<div className='rightPlate'></div>
						</div>

						<Button style={{ marginTop: '5%', background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<ArrowForwardIosIcon />} className='plateValidationBtn' onClick={plateValidation}>
							Chercher
						</Button>
					</div>
				</>
			) : (
				<>
					<div className='prez-input' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
						<div className='progress-bar'>
							<div className='progress-done' style={{ opacity: 1, width: `100%` }}>
								Résultat de votre recherche
							</div>
						</div>
					</div>

					{loading ? (
						<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
							<div style={{ width: '100%' }}>
								<h1 className='title-stepInput' style={{ color: 'white', textShadow: '2px 2px 2px grey', textAlign: 'center' }}>
									Envoi en cours...
								</h1>
							</div>
							<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<PuffLoader />
							</div>
						</div>
					) : (
						<>
							{!error && status === 'signalé' ? (
								<>
									<div className='visual-plate'>
										<div className='plate-result'>
											<div className='leftPlate'></div>
											<div className='plateNum-result' style={{ fontSize: '1.7rem!important' }}>
												{plate}
											</div>
											<div className='rightPlate'></div>
										</div>
									</div>

									<p style={{ color: 'white', textShadow: '0px 0px 8px #ffffff91' }}> Signalement le {date} </p>

									<div className='card-done'>
										{pic1 !== 'none' ? (
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
												<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
													<img style={{ width: '33%', borderRadius: '10px' }} src={pic1} alt='pic1' />
													{pic2 !== 'none' && <img style={{ width: '33%', borderRadius: '10px' }} src={pic2} alt='pic2' />}
													{pic3 !== 'none' && <img style={{ width: '33%', borderRadius: '10px' }} src={pic3} alt='pic3' />}
												</div>
											</div>
										) : (
											<h3 className='title-moduleInput' style={{ color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
												Sans photos additionnelles
											</h3>
										)}
									</div>

									<div className='card-map'>
										<LoadScript googleMapsApiKey='AIzaSyA2XIIiVQT-8jeyeFLfKpT3JyDkRBpzD5A'>
											<GoogleMap mapContainerStyle={{ width: '100%', height: '200px' }} center={{ lat: lat, lng: long }} zoom={17}>
												<Marker
													position={{ lat: lat, lng: long }}
													animation={2}
													onClick={() => {
														setSelected(true);
													}}
												/>
												{selected && (
													<InfoWindow
														onCloseClick={() => {
															setSelected(null);
														}}
														position={{ lat: lat, lng: long }}>
														<div>
															<h4 style={{ fontSize: '12px' }}>VÉHICULE : {plate}</h4>
															<p style={{ fontSize: '10px' }}>Adresse : {address}</p>
														</div>
													</InfoWindow>
												)}
											</GoogleMap>
										</LoadScript>
									</div>

									<div style={{ width: '80%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
										<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }} variant='contained' startIcon={<InfoIcon />} size='small' className='RedoBtn' onClick={handleOpenInfo}>
											Infos
										</Button>

										<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CheckIcon />} size='small' className='DoneBtn' onClick={handleOpen}>
											C'est réglé !
										</Button>
									</div>

									<Modal open={openInfo} onClose={handleClose} style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<div className='card-modal'>
											<div className='progress-done' style={{ opacity: 1, width: `200px`, marginBottom: '40px' }}>
												Instructions
											</div>

											<div>
												{more && (
													<div style={{ display: 'flex', marginBottom: '30px' }}>
														<Icon style={{ color: '#f47597', marginRight: '10px' }}>info</Icon>
														<p style={{ fontSize: '12px', color: '#404040e0' }}>
															Information complémentaire : <span style={{ fontWeight: 'bolder' }}>{more}</span>{' '}
														</p>
													</div>
												)}
												<div style={{ display: 'flex', marginBottom: '30px' }}>
													<Icon style={{ color: '#f47597', marginRight: '10px' }}>account_box</Icon>
													<p style={{ fontSize: '12px', color: '#404040e0', display: 'flex', alignItems: 'center' }}>Préparez votre carte grise</p>
												</div>

												<div style={{ display: 'flex', marginBottom: '30px' }}>
													<Icon style={{ color: '#f47597', marginRight: '10px' }}>policy</Icon>
													<p style={{ fontSize: '12px', color: '#404040e0', display: 'flex', alignItems: 'center' }}>Contacter le Commissariat le plus proche de la zone signalée</p>
												</div>

												<div style={{ display: 'flex', marginBottom: '30px' }}>
													<Icon style={{ color: '#f47597', marginRight: '10px' }}>business</Icon>
													<p style={{ fontSize: '12px', color: '#404040e0', display: 'flex', alignItems: 'center' }}>Contacter votre assurance</p>
												</div>
											</div>
										</div>
									</Modal>

									<Modal open={open} onClose={handleClose} style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<div className='card-modal'>
											<div className='progress-done' style={{ opacity: 1, width: `200px`, height: '40px', marginBottom: '40px', padding: '0 10px 0 10px', fontSize: '15px' }}>
												<Icon style={{ color: 'white', marginRight: '10px' }}>warning</Icon>Action Définitive<Icon style={{ color: 'white', marginLeft: '10px' }}>warning</Icon>
											</div>

											<div>
												<div style={{ marginBottom: '40px' }}>
													<p style={{ fontSize: '12px', color: '#404040e0', marginBottom: '20px' }}>
														Nous sommes heureux de savoir que <span style={{ fontWeight: 'bolder', textShadow: '1px 1px 1px rgba(0,0,0, 0.1)' }}>Watcher</span> vous a permis de récupérer votre véhicule.
													</p>
													<p style={{ fontSize: '12px', color: '#404040e0' }}>Si vous confirmez cette récupération, votre véhicule n'apparaîtra plus dans les recherches.</p>
												</div>
											</div>

											<Link to='/' style={{ textDecoration: 'none' }}>
												<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CheckIcon />} size='small' className='DoneBtn' onClick={jobDone}>
													Je Confirme
												</Button>
											</Link>
										</div>
									</Modal>
								</>
							) : (
								<>
									<div className='card-error' style={{ textAlign: 'center' }}>
										<h3 className='title-moduleInput' style={{ fontSize: '15px', width: '250px', marginTop: '5%', marginBottom: '5%', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
											Votre véhicule n'a pas encore été trouvé...
										</h3>
										<img src={errorPic} className='insert-pic' alt='ajouter' />
										{/* <p className='title-moduleInput' style={{ fontSize: '15px', width: '250px', marginTop: '5%', marginBottom: '5%', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
											Recevoir une notification dès que celui-ci est signalé ?
										</p>
										<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }} variant='contained'>
											Oui
										</Button> */}
									</div>

									<div style={{ marginTop: '10%', width: '90%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
										<Link to='/' style={{ textDecoration: 'none' }}>
											<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<ReplyIcon />}>
												Revenir
											</Button>
										</Link>
									</div>
								</>
							)}
						</>
					)}
				</>
			)}
		</section>
	);
}

export default SearchPlate;
