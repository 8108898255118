import React, { useState, useContext, useRef, useEffect } from "react";
import { LocalContext } from "../Context/LocalContext";
import { PlateContext } from "../Context/PlateContext";

import successPic from "../../../assets/success.png";
import PuffLoader from "../../Spinner";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";
import ReplyIcon from "@material-ui/icons/Reply";

import { fireStore } from "../../../firebase";

function StepInput() {
	// eslint-disable-next-line
	const [step, setStep, validPlate, setValidPlate, plate, setPlate] = useContext(LocalContext);
	const [zone1, setZone1, zone2, setZone2, zone3, setZone3] = useContext(PlateContext);
	const [space, setSpace] = useState(false);
	const [style, setStyle] = useState({});
	const [text, setText] = useState("");
	const [plateExists, setPlateExists] = useState(false);
	const input2 = useRef();
	const input3 = useRef();
	const [loading, setLoading] = useState(false);

	let plates = fireStore.collection("plates");

	const plateValidation = () => {
		setLoading(true);
		if ((zone1.length === 2) & (zone2.length === 3) & (zone3.length === 2)) {
			plates.where("num", "==", `${zone1}-${zone2}-${zone3}`).onSnapshot((querySnapshot) => {
				const data = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				if (data.length == 0) {
					setPlate(zone1 + "-" + zone2 + "-" + zone3);
					setValidPlate(1);
					setStep("pics");
					setLoading(false);
				} else {
					setPlateExists(true);
					setLoading(false);
				}
			});
		} else {
			setValidPlate(0);
			setLoading(false);
		}
	};

	const scan = () => {
		setStep("scan");
	};

	const handleZone1 = (zone1) => {
		const formattedZone1 = zone1.replace(/[^a-zA-Z]/, "").toUpperCase();
		setZone1(formattedZone1);
		if (zone1.length === 2) {
			input2.current.focus();
		}
	};

	const handleZone2 = (zone2) => {
		const formattedZone2 = zone2.replace(/\D+/, "");
		setZone2(formattedZone2);
		if (zone2.length === 3) {
			input3.current.focus();
		}
	};

	const handleZone3 = (zone3) => {
		const formattedZone3 = zone3.replace(/[^a-zA-Z]/, "").toUpperCase();
		setZone3(formattedZone3);
	};

	const hide = () => {
		setSpace(true);
	};

	const display = () => {
		setSpace(false);
	};

	useEffect(() => {
		setTimeout(() => {
			setStyle({ opacity: 1, width: "43%" });
		}, 200);
		setTimeout(() => {
			setText("Étape 1/3");
		}, 1200);
	}, []);

	return (
		<section className="section-stepInput">
			<div className="prez-input" style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
				<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
					<Link to="/" style={{ textDecoration: "none", marginRight: "15px" }}>
						<div className="back-top-btn">
							<ReplyIcon />
						</div>
					</Link>

					<div className="progress-bar">
						<div className="progress-done" style={style}>
							{text}
						</div>
					</div>
				</div>
				<h1 className="title-stepInput" style={{ color: "white", textShadow: "2px 2px 2px grey", textAlign: "center" }}>
					PLAQUE D'IMMATRICULATION
				</h1>
			</div>
			<>
				{loading ? (
					<>
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly" }}>
							<div style={{ width: "100%" }}>
								<h1 className="title-stepInput" style={{ color: "white", textShadow: "2px 2px 2px grey", textAlign: "center" }}>
									Merci de patienter...
								</h1>
							</div>
							<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<PuffLoader />
							</div>
						</div>
					</>
				) : (
					<>
						{validPlate === 0 && (
							<>
								<p style={{ color: "white", textAlign: "center", fontStyle: "italic" }}>Veuillez vérifier que tous les champs soient bien remplis</p>
							</>
						)}

						<>
							{plateExists ? (
								<>
									<div className="prez-done">
										<div className="progress-bar">
											<div className="progress-done" style={{ opacity: 1, width: `100%` }}>
												Merci pour votre engagement
											</div>
										</div>
									</div>

									<div className="card-done" style={{ textAlign: "center" }}>
										<img style={{ marginTop: "5%", width: "50%" }} src={successPic} alt="La plaque à bien été envoyée" />
										<h4 className="title-moduleInput" style={{ fontSize: "13px", width: "250px", marginTop: "5%", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
											Cette plaque à déjà été signalée par un membre de la communauté <span style={{ fontWeight: "bolder", textShadow: "1px 1px 1px rgba(0,0,0, 0.1)" }}>Watcher</span>
										</h4>
									</div>

									<div className="choice-pic" style={{ marginTop: "10%", display: "flex", alignItems: "space-between", justifyContent: "center" }}>
										<Link to="/" style={{ textDecoration: "none" }}>
											<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white", marginRight: "10px" }} variant="contained" startIcon={<ReplyIcon />}>
												Revenir
											</Button>
										</Link>
									</div>
								</>
							) : (
								<>
									<div className="card module-input">
										<h3 className="title-moduleInput" style={{ width: "250px", fontWeight: "lighter", marginTop: "15px", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
											Retranscrivez la plaque souhaitée ici
										</h3>
										<div className="plate" style={{ marginTop: "10%", marginBottom: "10%" }}>
											<div className="leftPlate"></div>
											<div className="plateNum">
												<input type="text" onFocus={hide} onBlur={display} className="zone1" placeholder="AA" value={zone1} onChange={(e) => handleZone1(e.target.value)} maxLength="2" required />
												<input type="text" onFocus={hide} onBlur={display} ref={input2} className="zone2" placeholder="123" value={zone2} onChange={(e) => handleZone2(e.target.value)} maxLength="3" required />
												<input type="text" onFocus={hide} onBlur={display} ref={input3} className="zone3" placeholder="AA" value={zone3} onChange={(e) => handleZone3(e.target.value)} maxLength="2" required />
											</div>
											<div className="rightPlate"></div>
										</div>

										<Button style={{ marginTop: "5%", background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" startIcon={<ArrowForwardIosIcon />} className="plateValidationBtn" onClick={plateValidation}>
											Continuer
										</Button>
									</div>
								</>
							)}
							{/* 					{!space && (
								<div className="module-scan card">
									<h1 className="title-moduleInput" style={{ width: "300px", marginBottom: "5%", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
										Ou en utilisant l'appareil photo
									</h1>
									<Button style={{ marginTop: "5%", background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" startIcon={<ArrowForwardIosIcon />} className="scanPlate" onClick={scan}>
										Scannez
									</Button>
								</div>
							)} */}
						</>
					</>
				)}
			</>
		</section>
	);
}

export default StepInput;
