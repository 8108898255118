import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';

firebase.initializeApp({
	apiKey: 'AIzaSyCr5gAS-VM2nfJizbFrXkg6m1crdnhgIVM',
	authDomain: 'watcher-028.firebaseapp.com',
	databaseURL: 'https://watcher-028.firebaseio.com',
	projectId: 'watcher-028',
	storageBucket: 'watcher-028.appspot.com',
	messagingSenderId: '464287173610',
	appId: '1:464287173610:web:74674357ad5417f9264093',
	measurementId: 'G-WJSE9GGSKP',
});

firebase.analytics();
const fireAuth = firebase.auth();
const fireStore = firebase.firestore();
const fireCloud = firebase.storage().ref();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export { fireAuth, fireStore, fireCloud, googleProvider, facebookProvider };
