import React, { useState, createContext } from "react";

export const PlateContext = createContext()

export const DataPlateContext = props => {
	const [zone1, setZone1] = useState("");
	const [zone2, setZone2] = useState("");
	const [zone3, setZone3] = useState("");


	return (

		<PlateContext.Provider value={[zone1, setZone1, zone2, setZone2, zone3, setZone3]}>
			{props.children}
		</PlateContext.Provider>

	)
}