import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DataLocalContext } from "./components/AddPlate/Context/LocalContext";
import { DataPicContext } from "./components/AddPlate/Context/PicContext";

import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import AddPlate from "./components/AddPlate/AddPlate";
import SearchPlate from "./components/SearchPlate/SearchPlate";
import Login from "./components/SearchPlate/Login";
import SignUp from "./components/SearchPlate/SignUp";
import User from "./components/User/User";
import "./App.css";

function App() {
	return (
		<div className="App">
			<DataLocalContext>
				<Router>
					<Header />
					<Switch>
						<Route path="/" exact component={Home} />

						<DataPicContext>
							<Route path="/ajouter-plaque" component={AddPlate} />
							<PrivateRoute path="/chercher-plaque" component={SearchPlate} />
							<Route path="/login" component={Login} />
							<Route path="/signup" component={SignUp} />
							<Route path="/user" component={User} />
						</DataPicContext>
					</Switch>
				</Router>
			</DataLocalContext>
		</div>
	);
}

export default App;
