import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, updateProfile, updateEmail, updatePassword, signOut } from 'firebase/auth';
import Modal from '@material-ui/core/Modal';
import CheckIcon from '@material-ui/icons/Check';
import ReplyIcon from '@material-ui/icons/Reply';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import { useAuthState } from 'react-firebase-hooks/auth';
import { fireAuth, fireStore } from '../../firebase';
import { LocalContext } from '../AddPlate/Context/LocalContext';

const User = () => {
	const [step, setStep, userName, setUserName] = useContext(LocalContext);

	const history = useHistory();
	const [fireUser] = useAuthState(fireAuth);
	const auth = getAuth();

	let users = fireStore.collection('users');

	const [docId, setDocId] = useState();
	const [newName, setNewName] = useState('');
	const [newMail, setNewMail] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [passwordBis, setPasswordBis] = useState('');
	const [passCheck, setPassCheck] = useState(false);
	const [passShort, setPassShort] = useState(false);
	const [zone1, setZone1] = useState('');
	const [zone2, setZone2] = useState('');
	const [zone3, setZone3] = useState('');
	const input2 = useRef();
	const input3 = useRef();
	const [loading, setLoading] = useState(false);
	const [space, setSpace] = useState(false);
	const [plate, setPlate] = useState('');
	const [validPlate, setValidPlate] = useState('');
	const [isError, setIsError] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [open, setOpen] = useState(false);
	const [updateType, setUpdateType] = useState();
	const [inputToggle, setInputToggle] = useState(false);

	if (fireUser && users) {
		users
			.where('mail', '==', `${fireUser.email}`)
			.get()
			.then((snapshot) => {
				snapshot.docs.map((doc) => {
					setUserName(doc.data().name);
					setDocId(doc.id);
				});
			});
	}

	setStep(0);

	const handleZone1 = (zone1) => {
		const formattedZone1 = zone1.replace(/[^a-zA-Z]/, '').toUpperCase();
		setZone1(formattedZone1);
		if (zone1.length === 2) {
			input2.current.focus();
		}
	};

	const handleZone2 = (zone2) => {
		const formattedZone2 = zone2.replace(/\D+/, '');
		setZone2(formattedZone2);
		if (zone2.length === 3) {
			input3.current.focus();
		}
	};

	const handleZone3 = (zone3) => {
		const formattedZone3 = zone3.replace(/[^a-zA-Z]/, '').toUpperCase();
		setZone3(formattedZone3);
	};

	const hide = () => {
		setSpace(true);
	};

	const display = () => {
		setSpace(false);
	};

	const plateValidation = () => {
		setLoading(true);
		if ((zone1.length === 2) & (zone2.length === 3) & (zone3.length === 2)) {
			setPlate(zone1 + '-' + zone2 + '-' + zone3);
			setValidPlate(1);
			setLoading(false);
		} else {
			setValidPlate(0);
			setLoading(false);
		}
	};

	const logout = () => {
		signOut(auth)
			.then(() => {
				setUserName();
				history.push('/');
				window.location.reload();
			})
			.catch((error) => {
				console.log('error with signout :', error);
			});
	};

	const updateName = () => {
		setUpdateType('pseudo');
		setIsSuccess(false);
		if (newName !== '') {
			if (/^[A-Za-z ]{3,25}$/.test(newName)) {
				if (newName !== userName) {
					setOpen(true);
					setIsError(false);
				} else setIsError("Le pseudo n'a pas changé");
			} else {
				setIsError("Le format du pseudo n'est pas correct");
			}
		} else {
			setIsError('Le champs à modifier est vide');
		}
	};

	const updateMail = () => {
		setUpdateType('mail');
		setIsSuccess(false);

		if (newMail !== '') {
			if (newMail !== fireUser.email) {
				if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(newMail)) {
					setOpen(true);
					setIsError(false);
				} else {
					setIsError("Le format de l'adresse email n'est pas valide");
				}
			} else {
				setIsError(`l'adresse email n'a pas changée`);
			}
		} else {
			setIsError('Le champs à modifier est vide');
		}
	};

	const updatePwd = () => {
		setUpdateType('mot de passe');
		setIsSuccess(false);

		if (newPassword !== '') {
			if (newPassword.length > 5) {
				setOpen(true);
				setIsError(false);
			} else {
				setIsError('Votre mot de passe est trop court');
			}
		} else {
			setIsError('Le champs à modifier est vide');
		}
	};

	const confirmedUpdate = (type) => {
		if (type === 'pseudo') {
			updateProfile(auth.currentUser, {
				displayName: newName,
			})
				.then(() => {
					users.doc(docId).update({ name: newName });
					setIsSuccess(true);
					setOpen(false);
					console.log('name updated');
				})
				.catch((error) => {
					setIsError('Désolé une erreur à empêché la modification');
					setOpen(false);
					console.log('erreur lors de la mise à jour du pseudo', error);
				});
		}
		if (type === 'mail') {
			updateEmail(auth.currentUser, newMail)
				.then(() => {
					users.doc(docId).update({ mail: newMail });
					setIsSuccess(true);
					setOpen(false);
					console.log('mail updated');
				})
				.catch((error) => {
					setIsError('Désolé, une erreur à empêché la modification');
					setOpen(false);
					console.log("erreur lors de la mise à jour de l'adresse email", error);
				});
		}
		if (type === 'mot de passe') {
			updatePassword(auth.currentUser, newPassword)
				.then(() => {
					setIsSuccess(true);
					setOpen(false);
					console.log('pwd updated');
				})
				.catch((error) => {
					setIsError('Désolé, une erreur à empêché la modification');
					setOpen(false);
					console.log('erreur lors de la mise à jour du mdp', error);
				});
		}
	};

	return (
		<section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', height: '100%' }}>
			<div className='prez-geoloc'>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<div
						className='back-top-btn'
						style={{ marginRight: '15px' }}
						onClick={() => {
							history.push('/');
						}}>
						<ReplyIcon />
					</div>

					<div className='progress-bar'>
						<div className='progress-done' style={{ opacity: 1, width: '100%' }}>
							Votre Compte
						</div>
					</div>
				</div>
			</div>
			{isError}
			{isSuccess && <p className='warning'>La modification à bien été prise en compte</p>}
			{fireUser?.providerData[0]?.providerId === 'password' ? (
				<div className='card module-input'>
					<h3 className='title-moduleInput' style={{ width: '250px', fontWeight: 'lighter', marginTop: '25px', marginBottom: '25px', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
						Modifiez votre profil
					</h3>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
						<div style={{ display: 'flex' }}>
							<Icon style={{ color: '#f47597', paddingTop: '5px', marginRight: '15px', paddingBottom: '24px' }}>person</Icon>
							<input
								type='texte'
								placeholder={userName}
								value={newName}
								onChange={(e) => {
									setNewName(e.target.value);
								}}
								className={isError && updateType === 'pseudo' ? `bad-input` : ''}
								required
								style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
							/>
							<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<EditIcon />} onClick={updateName}></Button>
						</div>
						<div style={{ display: 'flex' }}>
							<Icon style={{ color: '#f47597', paddingTop: '5px', marginRight: '15px', paddingBottom: '24px' }}>mail</Icon>
							<input
								type='email'
								placeholder={fireUser.email}
								value={newMail}
								onChange={(e) => {
									setNewMail(e.target.value);
								}}
								className={isError && updateType === 'mail' ? `bad-input` : ''}
								required
								style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
							/>
							<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<EditIcon />} onClick={updateMail}></Button>
						</div>
						<div style={{ display: 'flex' }}>
							<Icon style={{ color: '#f47597', paddingTop: '5px', marginRight: '15px', paddingBottom: '24px' }}>https</Icon>
							<input
								type='password'
								value={newPassword}
								onChange={(e) => {
									setNewPassword(e.target.value);
								}}
								className={isError && updateType === 'mot de passe' ? `bad-input` : ''}
								required
								style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }}
							/>
							<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<EditIcon />} onClick={updatePwd}></Button>
						</div>
					</div>
				</div>
			) : (
				<div className='card module-input'>
					<h3 className='title-moduleInput' style={{ width: '250px', fontWeight: 'lighter', marginTop: '25px', marginBottom: '25px', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
						{userName}
					</h3>
					<h3 className='title-moduleInput' style={{ width: '250px', fontWeight: 'lighter', marginTop: '25px', marginBottom: '25px', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
						{fireUser?.email}
					</h3>
				</div>
			)}
			{/* 			<div className="card module-input">
				<h3 className="title-moduleInput" style={{ width: "250px", fontWeight: "lighter", marginTop: "15px", color: "#FF8E53", textShadow: "0px 0px 3px white", textAlign: "center" }}>
					Suivre un véhicule pour recevoir une notification
				</h3>
				<div className="plate" style={{ marginTop: "10%", marginBottom: "10%" }}>
					<div className="leftPlate"></div>
					<div className="plateNum">
						<input type="text" onFocus={hide} onBlur={display} className="zone1" placeholder="AA" value={zone1} onChange={(e) => handleZone1(e.target.value)} maxLength="2" required />
						<input type="text" onFocus={hide} onBlur={display} ref={input2} className="zone2" placeholder="123" value={zone2} onChange={(e) => handleZone2(e.target.value)} maxLength="3" required />
						<input type="text" onFocus={hide} onBlur={display} ref={input3} className="zone3" placeholder="AA" value={zone3} onChange={(e) => handleZone3(e.target.value)} maxLength="2" required />
					</div>
					<div className="rightPlate"></div>
				</div>

				<Button style={{ marginTop: "5%", background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" startIcon={<AddToPhotosIcon />} className="plateValidationBtn" onClick={plateValidation}>
					Ajouter
				</Button>
			</div> */}
			<Button style={{ marginTop: '5%', background: 'linear-gradient(45deg,#f9303a 30%, #fa4c4c 90%)', color: 'white' }} variant='contained' startIcon={<ExitToAppIcon />} onClick={logout}>
				Déconnexion
			</Button>

			<Modal open={open} onClose={() => setOpen(false)} style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className='card-modal'>
					<div style={{ display: 'flex', gap: '10px', flexDirection: 'row', alignItems: 'baseline' }}>
						<div
							className='back-top-btn'
							onClick={() => {
								setOpen(false);
							}}>
							<ReplyIcon />
						</div>
						<div className='progress-done' style={{ opacity: 1, width: `200px`, height: '40px', marginBottom: '40px', padding: '0 10px 0 10px', fontSize: '15px' }}>
							<Icon style={{ color: 'white', marginRight: '10px' }}>warning</Icon>Modification
						</div>
					</div>
					<div>
						<div style={{ marginBottom: '40px' }}>
							<p style={{ fontSize: '12px', color: '#404040e0', marginBottom: '20px' }}>
								Êtes-vous sûr de vouloir changer votre {updateType} actuel {updateType === 'pseudo' ? userName : updateType === 'mail' && fireUser.email} par :
							</p>
							<p style={{ fontSize: '12px', color: '#404040e0' }}>{updateType === 'pseudo' ? newName : updateType === 'mail' && newMail}</p>
							{updateType === 'mot de passe' && (
								<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
									{!inputToggle ? <input type='password' value={newPassword} disabled style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }} /> : <input type='text' value={newPassword} disabled style={{ height: '40px', border: 'none', paddingLeft: '5px', color: '#000000a3', fontWeight: 'bold' }} />}
									<div
										onClick={() => {
											setInputToggle((prev) => !prev);
										}}>
										<Visibility />
									</div>
								</div>
							)}
						</div>
					</div>

					<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white' }} variant='contained' startIcon={<CheckIcon />} size='small' className='DoneBtn' onClick={() => confirmedUpdate(updateType)}>
						Je Confirme
					</Button>
				</div>
			</Modal>
		</section>
	);
};

export default User;
