import React, { useState, useContext } from 'react';

import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { LocalContext } from './Context/LocalContext';
import { PicContext } from './Context/PicContext';
import { Link } from 'react-router-dom';

import okPic from '../../assets/ok.png';
import errorPic from '../../assets/error.png';
import successPic from '../../assets/success.png';

import PuffLoader from '../Spinner';
import VisualPlate from './StepPlate/VisualPlate';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import ReplyIcon from '@material-ui/icons/Reply';

import dayjs from 'dayjs';
import { fireStore, fireCloud } from '../../firebase';

function StepDone() {
	// eslint-disable-next-line
	const [step, setStep, validPlate, setValidPlate, plate, setPlate, lat, setLat, long, setLong, address, setAddress, more, setMore] = useContext(LocalContext);
	const [dataUri1, setDataUri1, dataUri2, setDataUri2, dataUri3, setDataUri3] = useContext(PicContext); // eslint-disable-line
	const [selected, setSelected] = useState(false);
	const [done, setDone] = useState(false);
	const [loading, setLoading] = useState(false);

	let plates = fireStore.collection('plates');
	let pic1 = fireCloud.child(plate + '-pic1.jpeg');
	let pic2 = fireCloud.child(plate + '-pic2.jpeg');
	let pic3 = fireCloud.child(plate + '-pic3.jpeg');

	const undo = () => {
		setStep(0);
		setValidPlate('');
		setPlate('');
		setLat('');
		setLong('');
		setMore(null);
		setAddress('');
		setDataUri1('');
		setDataUri2('');
		setDataUri3('');
	};

	const postData = async () => {
		setDone(true);
		setLoading(true);

		if (dataUri1 && !dataUri2 && !dataUri3) {
			const upload1 = pic1.putString(dataUri1, 'data_url');
			upload1.on('state_changed', () => {
				upload1.snapshot.ref
					.getDownloadURL()
					.then((downloadURL) => {
						console.log(downloadURL);
						plates
							.add({
								num: plate,
								lat: lat,
								long: long,
								more: more,
								pic1: downloadURL,
								pic2: 'none',
								pic3: 'none',
								status: 'signalé',
								date: dayjs().format('DD/MM/YYYY'),
							})
							.then(() => {
								setDone(true);
								setLoading(false);
								setMore(null);
							})
							.catch((err) => {
								setDone('error');
								setLoading(false);
								console.log(err);
							});
					})
					.catch((err) => console.log(err));
			});
		}

		if (dataUri1 && dataUri2 && !dataUri3) {
			let upload1 = pic1.putString(dataUri1, 'data_url');
			upload1.on('state_changed', function () {
				upload1.snapshot.ref.getDownloadURL().then((downloadURL1) => {
					let pic1url = downloadURL1;
					let upload2 = pic2.putString(dataUri2, 'data_url');
					upload2.on('state_changed', function () {
						upload2.snapshot.ref
							.getDownloadURL()
							.then((downloadURL2) => {
								let pic2url = downloadURL2;
								plates
									.add({
										num: plate,
										lat: lat,
										long: long,
										more: more,
										pic1: pic1url,
										pic2: pic2url,
										pic3: 'none',
										status: 'signalé',
										date: dayjs().format('DD/MM/YYYY'),
									})
									.then(() => {
										setDone(true);
										setLoading(false);
										setMore(null);
									})
									.catch((err) => {
										setLoading(false);
										setDone('error');
										console.log(err);
									});
							})
							.catch((err) => console.log(err));
					});
				});
			});
		}

		if (dataUri1 && dataUri2 && dataUri3) {
			let upload1 = pic1.putString(dataUri1, 'data_url');
			upload1.on('state_changed', async () => {
				await upload1.snapshot.ref
					.getDownloadURL()
					.then((downloadURL1) => {
						let pic1url = downloadURL1;

						let upload2 = pic2.putString(dataUri2, 'data_url');
						upload2.on('state_changed', async () => {
							await upload2.snapshot.ref
								.getDownloadURL()
								.then((downloadURL2) => {
									let pic2url = downloadURL2;

									let upload3 = pic3.putString(dataUri3, 'data_url');
									upload3.on('state_changed', async () => {
										await upload3.snapshot.ref
											.getDownloadURL()
											.then((downloadURL3) => {
												let pic3url = downloadURL3;
												plates
													.add({
														num: plate,
														lat: lat,
														long: long,
														more: more,
														pic1: pic1url,
														pic2: pic2url,
														pic3: pic3url,
														status: 'signalé',
														date: dayjs().format('DD/MM/YYYY'),
													})
													.then(() => {
														setDone(true);
														setLoading(false);
														setMore(null);
													})
													.catch((err) => {
														setLoading(false);
														setDone('error');
														console.log(err);
													});
											})
											.catch((err) => console.log(err));
									});
								})
								.catch((err) => console.log(err));
						});
					})
					.catch((err) => console.log(err));
			});
		}

		if (!dataUri1 && !dataUri2 && !dataUri3) {
			plates
				.add({
					num: plate,
					lat: lat,
					long: long,
					more: more,
					pic1: 'none',
					pic2: 'none',
					pic3: 'none',
					status: 'signalé',
					date: dayjs().format('DD/MM/YYYY'),
				})
				.then(() => {
					setDone(true);
					setLoading(false);
					setMore(null);
				})
				.catch((err) => {
					setDone('error');
					setLoading(false);
					console.log(err);
				});
		}
	};

	const backTop = () => {
		setStep('geoloc');
		setLat('');
		setLong('');
		setAddress('');
	};

	return (
		<section className='section-stepDone'>
			{!done ? (
				<>
					<div className='prez-done'>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<div className='back-top-btn' style={{ marginRight: '15px' }} onClick={backTop}>
								<ReplyIcon />
							</div>

							<div className='progress-bar'>
								<div className='progress-done' style={{ opacity: 1, width: `100%` }}>
									Vérifications Finales
								</div>
							</div>
						</div>
					</div>

					<VisualPlate></VisualPlate>

					<div className='card-done'>
						{dataUri1 ? (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
									{dataUri1 && <img style={{ width: '33%', borderRadius: '10px' }} src={dataUri1} alt='pic1' />}
									{dataUri2 && <img style={{ width: '33%', borderRadius: '10px' }} src={dataUri2} alt='pic2' />}
									{dataUri3 && <img style={{ width: '33%', borderRadius: '10px' }} src={dataUri3} alt='pic3' />}
								</div>
							</div>
						) : (
							<h3 className='title-moduleInput' style={{ color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
								Sans photos additionnelles
							</h3>
						)}
					</div>

					<div className='card-map'>
						<LoadScript googleMapsApiKey='AIzaSyA2XIIiVQT-8jeyeFLfKpT3JyDkRBpzD5A'>
							<GoogleMap mapContainerStyle={{ width: '100%', height: '200px' }} center={{ lat: long, lng: lat }} zoom={17}>
								<Marker
									position={{ lat: long, lng: lat }}
									animation={2}
									onClick={() => {
										setSelected(true);
									}}
								/>
								{selected && (
									<InfoWindow
										onCloseClick={() => {
											setSelected(null);
										}}
										position={{ lat: long, lng: lat }}>
										<div>
											<h4 style={{ fontSize: '12px' }}>VÉHICULE : {plate}</h4>
											<p style={{ fontSize: '10px' }}>Adresse : {address}</p>
										</div>
									</InfoWindow>
								)}
							</GoogleMap>
						</LoadScript>
					</div>

					<div style={{ width: '80%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
						<Link to='/' style={{ textDecoration: 'none' }}>
							<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', width: '120px', marginRight: '10px' }} variant='contained' startIcon={<ReplayIcon />} className='RedoBtn' onClick={undo}>
								Annuler
							</Button>
						</Link>

						<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', width: '120px' }} variant='contained' startIcon={<CheckIcon />} className='DoneBtn' onClick={postData}>
							Valider
						</Button>
					</div>
				</>
			) : (
				<div style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
					{loading ? (
						<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
							<div style={{ width: '100%' }}>
								<h1 className='title-stepInput' style={{ color: 'white', textShadow: '2px 2px 2px grey', textAlign: 'center' }}>
									Envoi en cours...
								</h1>
							</div>
							<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<PuffLoader />
							</div>
						</div>
					) : (
						<>
							{done === 'error' ? (
								<>
									<div className='card-map' style={{ textAlign: 'center' }}>
										<h3 className='title-moduleInput' style={{ fontSize: '15px', width: '250px', marginTop: '5%', marginBottom: '5%', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
											Désolé, une erreur est survenue. Merci de bien vouloir réessayer
										</h3>
										<img src={errorPic} className='insert-pic' alt='ajouter' />
									</div>

									<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
										<Link to='/' style={{ textDecoration: 'none' }}>
											<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }} variant='contained' startIcon={<ReplyIcon />} onClick={undo}>
												Revenir
											</Button>
										</Link>
									</div>
								</>
							) : (
								<>
									<div className='prez-done'>
										<div className='progress-bar'>
											<div className='progress-done' style={{ opacity: 1, width: `100%` }}>
												Merci pour votre contribution !
											</div>
										</div>
									</div>

									<img src={okPic} style={{ width: '40%', maxWidth: '200px' }} alt='Plaque bien reçue' />

									<div className='card-done' style={{ textAlign: 'center' }}>
										<img style={{ marginTop: '5%', width: '50%' }} src={successPic} alt='La plaque à bien été envoyée' />
										<h4 className='title-moduleInput' style={{ fontSize: '13px', width: '250px', marginTop: '5%', color: '#FF8E53', textShadow: '0px 0px 3px white', textAlign: 'center' }}>
											Elle sera mise à la disponibilité de la communauté <span style={{ fontWeight: 'bolder', textShadow: '1px 1px 1px rgba(0,0,0, 0.1)' }}>Watcher</span>
										</h4>
									</div>

									<div className='choice-pic' style={{ marginTop: '10%', display: 'flex', alignItems: 'space-between', justifyContent: 'center' }}>
										<Link to='/' style={{ textDecoration: 'none' }}>
											<Button style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: 'white', marginRight: '10px' }} variant='contained' startIcon={<ReplyIcon />} onClick={undo}>
												Revenir
											</Button>
										</Link>
									</div>
								</>
							)}
						</>
					)}
				</div>
			)}
		</section>
	);
}

export default StepDone;
