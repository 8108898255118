import React, { useContext } from 'react';
import { LocalContext } from './Context/LocalContext';
import { DataPlateContext } from './Context/PlateContext';
import './AddPlate.css';

import StepInput from './StepPlate/StepInput';
import StepScan from './StepPlate/StepScan';
import StepPics from './StepPic/StepPics';
import StepGeoloc from './StepGeoloc/StepGeoloc';
import StepDone from './StepDone';

function AddPlate() {
	// eslint-disable-next-line
	const [step, setStep, validPlate, setValidPlate] = useContext(LocalContext);

	return (
		<section className='section-addplate' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			{step === 0 && (
				<DataPlateContext>
					<StepInput></StepInput>
				</DataPlateContext>
			)}

			{/* {step === "scan" && <StepScan></StepScan> } */}

			{step === 'pics' && <StepPics></StepPics>}

			{step === 'geoloc' && <StepGeoloc></StepGeoloc>}

			{step === 'done' && <StepDone></StepDone>}
		</section>
	);
}

export default AddPlate;
