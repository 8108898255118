import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import searchPic from "../../assets/search2.png";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

const SearchCard = () => {
	const smallMobile = useMediaQuery({ query: "(max-width: 500px)" });
	/* const mediumMobile = useMediaQuery({ query: '(min-width: 501px) and (max-width: 600px)' });
    const infra = useMediaQuery({ query: '(min-width: 601px) and (max-width: 800px)' });
    const supra = useMediaQuery({ query: '(min-width: 801px) and (max-width: 1200px)' });
    const mediumDesktop = useMediaQuery({ query: '(min-width: 1200px) and (max-width: 1800px)' }); */
	const bigDesktop = useMediaQuery({ query: "(min-width: 501px)" });

	return (
		<section className="search-card">
			<div className={`main-card ${smallMobile ? "small-card" : ""}${bigDesktop ? "big-card" : ""}`}>
				<h2 style={{color:"orange",fontSize:"12px", textAlign:"center"}}>Retrouvez votre véhicule disparu grâce à la communauté Watcher</h2>
				<img src={searchPic} className="search-pic" alt="icône rechercher une plaque d'immatriculation" />
				<Link to="/chercher-plaque" style={{ textDecoration: "none" }}>
					<Button style={{ background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", color: "white" }} variant="contained" className="search-btn" startIcon={<SearchIcon />}>
						Chercher plaque
					</Button>
				</Link>
			</div>
		</section>
	);
};

export default SearchCard;
